/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// window.PageFunctions.smoothScroll();
				// // JavaScript to be fired on all pages
				// window.MenuFunctions.setupMenuToggle();
				// window.MenuFunctions.setupSubMenuToggle();

				// turn off specified menu links
				$('li.no-link > a').click(function(e){
					e.preventDefault();
				});

				// hide/show menu on scroll behaviour
				var didScroll;
				var lastScrollTop = 0;
				var delta = 5;
				var navbar = $('.d-header-container');
				var d_header = $('.d-header');
				var navbarHeight = navbar.outerHeight();

				function hasScrolled() {
					var st = $(this).scrollTop();
					// Make sure they scroll more than delta
					if(Math.abs(lastScrollTop - st) <= delta) {
						return;
					}
					// If they scrolled down and are past the navbar, add class .nav-up.
					// This is necessary so you never see what is "behind" the navbar.
					if (st > lastScrollTop && st > navbarHeight){
						// Scroll Down
						// $('header').removeClass('nav-down').addClass('nav-up');
						navbar.addClass('scrolled');
						d_header.removeClass('active');
					} else {
						// Scroll Up
						if(st + $(window).height() < $(document).height()) {
							// $('header').removeClass('nav-up').addClass('nav-down');
							navbar.removeClass('scrolled');
							d_header.addClass('active');
						}
					}
					lastScrollTop = st;

					if ( st === 0 ) {
						d_header.removeClass('active');
					}
				}
				$(window).scroll(function(event){
					didScroll = true;
				});
				setInterval(function() {
					if (didScroll) {
						hasScrolled();
						didScroll = false;
					}
				}, 250);


				// when hovering on a menu item, trigger a hover on the first sub menu item 
				$('#main-menu>li>a').on('mouseover',function(){
					$(this).siblings('.sub-menu').children('li').first().addClass('hover');
				});

				$('#main-menu>li>a').on('mouseleave',function(){
					$(this).siblings('.sub-menu').children('li').first().removeClass('hover');
				});

				// $('.sub-menu').on('mouseover','li', function(){
				//     console.log('being fired');
				// });
				

				// add a view page button into the sub sub menu
				$('#main-menu>li>.sub-menu>li>a').each(function(){
					var view_link = $(this).attr('href');
					var view_btn = '<a class="button primary sub-menu--button" href="' + view_link + '">View Page</a>';
					$(this).siblings('.sub-menu').append(view_btn);
				});


				// turn off the sub sub menu links
				$('#main-menu>li>.sub-menu>li>.sub-menu>li>a').on('click',function(e){
					e.preventDefault();
				});


				// pull the menu callout into the menu
				var menu_callout = $('.d-header--callout-container').html();
				$('#main-menu > li > .sub-menu').each(function(){
					$(this).append(menu_callout);
				});


				// mobile menu js
				$('.d-header--burger').click(function(){
					$('.d-header-mobile').addClass('open');
					$('.d-header-mobile--off').addClass('active');
				});

				$('.d-header-mobile--off').click(function(){
					$('.d-header-mobile').removeClass('open');
					$('.d-header-mobile--off').removeClass('active');
					$('#mobile-menu .sub-menu').removeClass('active');
				});

				$('#mobile-menu .menu-item-has-children a').each(function(){
					// var has_child = $(this).children('a')[0].outerHTML;
					var has_child_title = $(this).html();
					$(this).siblings('.sub-menu').prepend('<li class="sub-menu-title"><a href="#">' + has_child_title + '</a></li>');
				});

				$('#mobile-menu > .menu-item-has-children > a').click(function(e){
					e.preventDefault();
					$(this).siblings('.sub-menu').addClass('active');
				});

				$('.sub-menu-title > a').click(function(e){
					e.preventDefault();
					$(this).parents('.sub-menu').removeClass('active');
				});


				// banner slicks
				$('.d-banner-slider').slick({
					'fade' : true,
					'infinite' : true,
					'dots' : true,
					'customPaging' : function(slider,i) {
						return '<button></button>' + '<span>' + $(slider.$slides[i]).data('nav') + '</span>';
					},
					'arrows' : false
				});


				// check the text colour on the next slide, and change slick nav if needed
				$('.d-banner-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
					var slider_text = $(slick.$slides[nextSlide]).data('text');
					$(this).removeClass('text-light text-dark');
					$(this).addClass(slider_text);
				});


				// testimonial slicks
				$('.d-testimonials--slick').slick({
					'fade' : true,
					'infinite' : true,
					'dots' : true,
					'arrows' : false
				});


				// service callouts dropdown
				$('.d-service-callout--sub-title').on('click',function(){
					$(this).toggleClass('active');
					$(this).siblings('.d-service-callout--sub-services').slideToggle();
				});


				// faqs
				$('.d-faq--question, .d-faq-toggle').on('click',function(){
					$(this).parent('.d-faq').toggleClass('faq-open');
					$(this).siblings('.d-faq--answer').slideToggle();
				});


				// blog filtering
				function updateBlogPosts(permalink){
					loadmore = $('.j-load-more');
					$('html,body').animate({
						scrollTop: $('.j-filter--scroll-target').offset().top - 100
					 }, 200 );
					window._fetch.callbackContent(permalink, function(newPage) {
						var $newPage = $(newPage);
						loadmore.find('.j-load-more--posts').html($newPage.find('.j-load-more--posts').html());
						loadmore.find('.o-post-index--filters').html($newPage.find('.o-post-index--filters').html());
						loadmore.find('.j-filter--count').html($newPage.find('.j-filter--count').html());
						loadmore.find('.j-load-more--pagination').html($newPage.find('.j-load-more--pagination').html());
						window._fetch.setURL(permalink);
						$('.o-post-index--filter').select2({
							minimumResultsForSearch: Infinity
						});
					});
				}
				$('.j-load-more').on('click','.o-posts--pagination a',function(e){
					e.preventDefault();
					updateBlogPosts($(this).attr('href'));
				   
				});
				$('.j-load-more').on('change', '.j-post-filter', function(){
					var permalink = $(this).data('permalink');
					var extension = "?";

					$('.j-post-filter').each(function(){

						var _this = $(this);
						var value = _this.val();
						var array = value.split('__');
						if(array[0].length > 1 ){
							permalink += extension + array[0] + "=" + array[1];
							extension = "&";
						}
					});
					updateBlogPosts(permalink);
				});


				// custom selects
				$('.o-post-index--filter, .gfield_select').select2({
					minimumResultsForSearch: Infinity
				});

				$(document).on('gform_post_render', function(event, form_id, current_page){
					$('.o-post-index--filter, .gfield_select').select2({
						minimumResultsForSearch: Infinity
					});
				});


				// form label behaviour
				// $('.gfield input, .gfield textarea').focus(function(){
				// 	$(this).parent('.ginput_container').siblings('.gfield_label').addClass('visible');
				// });
				// $('.gfield input, .gfield textarea').focusout(function(){
				// 	$(this).parent('.ginput_container').siblings('.gfield_label').removeClass('visible');
				// });
				// $('.select2').on('click',function(){
				// 	$(this).parent('.ginput_container').siblings('.gfield_label').addClass('visible');
				// });
				// $('.ginput_container_radio, .ginput_container_checkbox').siblings('.gfield_label').addClass('visible');


				// popup functionality
				$('a[href="#popup"]').on('click', function(e){
                    e.preventDefault();
                    $('.d-popup').addClass('open');

                    // if opened from mobile menu, close the mobile menu
                    $('.d-header-mobile').removeClass('open');
					$('.d-header-mobile--off').removeClass('active');
					$('#mobile-menu .sub-menu').removeClass('active');
                });
                $('.d-popup--off').on('click',function(){
                	$('.d-popup').removeClass('open');
                });


			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(document).on('gform_post_render', function( event, form_id, current_page ){

	// form label behaviour
	jQuery('.gfield input, .gfield textarea').focus(function(){
		jQuery(this).parent('.ginput_container').siblings('.gfield_label').addClass('visible');
	});
	jQuery('.gfield input, .gfield textarea').focusout(function(){
		jQuery(this).parent('.ginput_container').siblings('.gfield_label').removeClass('visible');
	});
	jQuery('.select2').on('click',function(){
		jQuery(this).parent('.ginput_container').siblings('.gfield_label').addClass('visible');
	});
	jQuery('.ginput_container_radio, .ginput_container_checkbox').siblings('.gfield_label').addClass('visible');

});