(function($, window, document) { // jQuery wrapper

    // Menu Functions
    window.MenuFunctions = {

        header: $("#wp-site-header"),
        menu: $("#wp-primary-menu"),
        menuToggle: $("#wp-menu-toggle"),

        // Toggle Main menu when menu toggle is clicked.
        setupMenuToggle : function (){
            this.header.on('click', this.menuToggle, function () {
                window.MenuFunctions.menu.slideToggle();
            });
        },

        // Creates sub menu toggle fields, and controls their function
        setupSubMenuToggle : function(){
            this.menu.find('.sub-menu').siblings('a').after('<span class="menu-slide"></span>');
            this.menu.find('.menu-slide').on('click',function(e){
                $(this).toggleClass('open');
                e.preventDefault();
                e.stopPropagation();
                $(this).siblings('.sub-menu').stop(true, true).slideToggle(200);
            });
        }


    };

}(window.jQuery, window, document)); // End of jQuery wrapper

